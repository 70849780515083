import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0,3,4,5,7,8,9];

export const dictionary = {
		"/": [12],
		"/(authenticated)/admin": [17,[3,4]],
		"/(authenticated)/admin/projects": [18,[3,4]],
		"/(authenticated)/admin/proposals": [19,[3,4]],
		"/(authenticated)/admin/templates": [~20,[3,4]],
		"/(authenticated)/admin/templates/[templateID]": [21,[3,4]],
		"/(authenticated)/admin/templates/[templateID]/[templateVersion]": [22,[3,4,5]],
		"/(authenticated)/admin/templates/[templateID]/[templateVersion]/[sectionID]": [~23,[3,4,5,6]],
		"/(authenticated)/admin/users": [24,[3,4]],
		"/(authenticated)/admin/users/[userID]": [25,[3,4,7]],
		"/(authenticated)/admin/users/[userID]/[profileID]": [26,[3,4,7,8]],
		"/(authenticated)/applications": [27,[3]],
		"/(authenticated)/applications/[proposalID]": [28,[3,9]],
		"/(authenticated)/applications/[proposalID]/edit": [~29,[3,9]],
		"/(authenticated)/applications/[proposalID]/export": [30,[3,9]],
		"/(authenticated)/applications/[proposalID]/review": [~31,[3,9]],
		"/(authenticate-account)/challenge": [13,[2]],
		"/(authenticate-account)/confirmation": [14,[2]],
		"/contact-us": [40],
		"/(authenticated)/grant-projects": [32,[3]],
		"/(authenticated)/grant-projects/[projectID]": [~33,[3]],
		"/(authenticated)/grants": [34,[3,10]],
		"/(authenticated)/grants/[grantID]": [~35,[3,10]],
		"/(authenticated)/grants/[grantID]/write": [36,[3,10]],
		"/(authenticated)/help": [37,[3]],
		"/(authenticate-account)/login": [15,[2]],
		"/(authenticated)/onboarding": [38,[11]],
		"/privacy-policy": [41],
		"/(authenticated)/settings": [39,[3]],
		"/(authenticate-account)/signup": [16,[2]],
		"/terms": [42]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';